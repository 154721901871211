.open-panel-button {
    color: #fff;
    font-size: 1.5em;
    margin-right: 0.5em;
    position: relative;
    z-index: 10;
    bottom: 0px;
}

.inverted {
    transform: scaleX(-1) rotate(-90deg);
}
